import React from 'react';
import { Switch } from 'react-router-dom';
import LayoutExternal from './components/shared/LayoutExternal';
import LayoutInternal from './components/shared/LayoutInternal';
import { AppRoute } from './components/shared/AppRoute';

import Login from './components/Login';
import TwoFactorAuthentication from './components/TwoFactorAuthentication';
import AutoLogin from './components/AutoLogin';
import LoginByToken from './components/LoginByToken';
import Register from './components/Register';
import ForgotPassword from './components/ForgotPassword';
import AccountResetPassword from './components/AccountResetPassword';
import AccountVerification from './components/AccountVerification';
import EnrollmentVerification from './components/EnrollmentVerification';
import UserNameUpdate from './components/UserNameUpdate';
import Landing from './components/Landing';

import Dashboard from './components/account/Dashboard';
import MonthlyStatements from './components/account/MonthlyStatements';
import TransactionHistory from './components/account/TransactionHistory';
import PlansDocuments from './components/account/PlansDocuments';
import Documents from './components/account/Documents';
import Plans from './components/account/Plans';
import MemberInfo from './components/account/MemberInfo';
import MemberProgram from './components/account/MemberProgram';
import RenewService from './components/account/RenewService';
import Usage from './components/account/Usage';
import MoveOut from './components/service/MoveOut';
import TransferService from './components/service/TransferService';
import AddLocation from './components/service/AddLocation';
import BackflowInspection from './components/service/BackflowInspection';
import Threshold from './components/account/Threshold';

import AutoPay from './components/payment/AutoPay';
import PayCreditCard from './components/payment/PayCreditCard';
import PayECheck from './components/payment/PayECheck';
import PayCrypto from './components/payment/PayCrypto';
import PaymentMethod from './components/payment/PaymentMethod';
import PaymentExtension from './components/payment/PaymentExtension';
import PaymentPlan from './components/payment/PaymentPlan';
import PaymentHistory from './components/payment/PaymentHistory';

import ContactUs from './components/profile/ContactUs';
import CustomerProfile from './components/profile/CustomerProfile';
import Notifications from './components/profile/Notifications';


export default () => (
    <Switch>
        <AppRoute exact path='/' component={Login} layout={LayoutExternal} />
        <AppRoute path='/twofactorauthentication' component={TwoFactorAuthentication} layout={LayoutExternal} />        
        <AppRoute path='/autologin' component={AutoLogin} layout={LayoutExternal} />  
        <AppRoute path='/loginbytoken' component={LoginByToken} layout={LayoutExternal} />
        <AppRoute path='/register' component={Register} layout={LayoutExternal} />
        <AppRoute path='/forgotpassword' component={ForgotPassword} layout={LayoutExternal} />
        <AppRoute path='/accountresetpassword' component={AccountResetPassword} layout={LayoutExternal} />
        <AppRoute path='/accountverification' component={AccountVerification} layout={LayoutExternal} />
        <AppRoute path='/enrollmentverification' component={EnrollmentVerification} layout={LayoutExternal} />
        <AppRoute path='/usernameupdate' component={UserNameUpdate} layout={LayoutExternal} />
        <AppRoute path='/landing' component={Landing} layout={LayoutExternal} />

        <AppRoute path="/myaccount/dashboard" component={Dashboard} layout={LayoutInternal} internal={true} />
        <AppRoute path="/myaccount/monthlystatements" component={MonthlyStatements} layout={LayoutInternal} internal={true} />
        <AppRoute path="/myaccount/transactionhistory" component={TransactionHistory} layout={LayoutInternal} internal={true} />
        <AppRoute path="/myaccount/plandocuments" component={PlansDocuments} layout={LayoutInternal} internal={true} />
        <AppRoute path="/myaccount/documents" component={Documents} layout={LayoutInternal} internal={true} />
        <AppRoute path="/myaccount/plans" component={Plans} layout={LayoutInternal} internal={true} />
        <AppRoute path="/myaccount/usage" component={Usage} layout={LayoutInternal} internal={true} />
        <AppRoute path="/myaccount/memberinfo" component={MemberInfo} layout={LayoutInternal} internal={true} />     
        <AppRoute path="/myaccount/memberprogram" component={MemberProgram} layout={LayoutInternal} internal={true} />
        <AppRoute path="/myaccount/renewal" component={RenewService} layout={LayoutInternal} internal={true} />
        <AppRoute path="/myaccount/moveout" component={MoveOut} layout={LayoutInternal} internal={true} />
        <AppRoute path="/myaccount/transferservice" component={TransferService} layout={LayoutInternal} internal={true} />
        <AppRoute path="/myaccount/AddLocation" component={AddLocation} layout={LayoutInternal} internal={true} />
        <AppRoute path="/myaccount/BackflowInspection" component={BackflowInspection} layout={LayoutInternal} internal={true} />

        <AppRoute path="/myaccount/autopay" component={AutoPay} layout={LayoutInternal} internal={true} />
        <AppRoute path="/myaccount/paycreditcard" component={PayCreditCard} layout={LayoutInternal} internal={true} />
        <AppRoute path="/myaccount/payecheck" component={PayECheck} layout={LayoutInternal} internal={true} />
        <AppRoute path="/myaccount/paycrypto" component={PayCrypto} layout={LayoutInternal} internal={true} />
        <AppRoute path="/myaccount/paymentmethods" component={PaymentMethod} layout={LayoutInternal} internal={true} />
        <AppRoute path="/myaccount/paymentextension" component={PaymentExtension} layout={LayoutInternal} internal={true} />
        <AppRoute path="/myaccount/paymentplan" component={PaymentPlan} layout={LayoutInternal} internal={true} />
        <AppRoute path="/myaccount/paymenthistory" component={PaymentHistory} layout={LayoutInternal} internal={true} />

        <AppRoute path="/myaccount/contactus" component={ContactUs} layout={LayoutInternal} internal={true} />
        <AppRoute path="/myaccount/customerprofile" component={CustomerProfile} layout={LayoutInternal} internal={true} />
        <AppRoute path="/myaccount/notifications" component={Notifications} layout={LayoutInternal} internal={true} />
        <AppRoute path="/myaccount/threshold" component={Threshold} layout={LayoutInternal} internal={true} />

        <AppRoute component={Login} layout={LayoutExternal} />
    </Switch>
);
