import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as AccountStore from '../../store/Account';
import * as CommonStore from '../../store/Common';
import T from 'i18n-react';
import MessageError from '../shared/MessageError';
import LoadingOverlay from '../shared/LoadingOverlay';
import DataGrid from '../shared/DataGrid';
import LoadingButton from '../shared/LoadingButton';
import ConfirmModal from '../shared/ConfirmModal';import { Tooltip } from 'reactstrap';

const Scroll = require('react-scroll');

const initialState = {
    activeTab: '1',
    fields: [
        { name: 'minimumThreshold', isRequired: true, minValue: 1, maxValue: 999 },
        { name: 'maximumThreshold', isRequired: true, minValue: 1, maxValue: 999 },
        { name: 'rolloverDays', isRequired: true, minValue: 1, maxValue: 180 },
    ],
    selectedSerialNumber: 0
};

class Threshold extends Component {

    constructor(props) {
        super(props);

        this.state = initialState;
    }

    componentWillMount() {
        Scroll.animateScroll.scrollToTop({ smooth: true, duration: 500 });
        document.title = T.translate('common.client') + ' | ' + T.translate('threshold.title');

        if (this.props.account.accountNumber) {
            this.props.thresholdMeterList();

            if (this.props.accountMeterList.length > 1) {
                this.setState({ selectedSerialNumber: this.props.accountMeterList[0].meterSerialNumber });
                this.props.thresholdInit(this.state.selectedSerialNumber);
            }
        }
    }

    componentWillReceiveProps(nextProps) {
        //when user refresh the browser - once we get the account - get screen data

        if ((nextProps.account.accountNumber && !this.props.account.accountNumber) || nextProps.account.accountNumber !== this.props.account.accountNumber) {

            this.props.thresholdMeterList();

            if (this.props.accountMeterList.length > 1) {
                this.setState({ selectedSerialNumber: this.props.accountMeterList[0].meterSerialNumber });
                this.props.thresholdInit(this.props.accountMeterList[0].meterSerialNumber);
            }
            else {
                this.setState({ selectedSerialNumber: 0 });
            }

        }
    }

    toggleActiveTab = (e) => {
        var tab = e.target.attributes['data-tab'].value;
        this.setState({ activeTab: tab });
    }

    onChangeMeter = (e) => {
        let state = this.state;
        state.selectedSerialNumber = e.target.value;

        if (e.target.value) {

            this.setState({ selectedSerialNumber: e.target.value });
            this.props.thresholdInit(state.selectedSerialNumber);
        }
        else {
            this.setState({ selectedSerialNumber: 0 });
        }
    }

    onChangeLow = (e) => {

        let val = this.convertNumberValue(e.target.value);

        let hasError = this.checkValue(val, "lowTreshold");

        this.props.setThresholdValue(this.props.thresholdDetails, "lowTreshold", val);

        if (!hasError) {
            this.props.setAccountError('');
        }
        else {
            this.props.thresholdError(hasError)
        }
    }

    onChangeHigh = (e) => {

        let val = this.convertNumberValue(e.target.value);

        let hasError = this.checkValue(val, "thresholdHigh");

        this.props.setThresholdValue(this.props.thresholdDetails, "thresholdHigh", val);

        if (!hasError) {
            this.props.setAccountError('');
        }
        else {
            this.props.thresholdError(hasError)
        }

    }

    onChangeRolloverDays = (e) => {

        let val = this.convertNumberValue(e.target.value);

        let hasError = this.checkValue(val, "rolloverDays");

        this.props.setThresholdValue(this.props.thresholdDetails, "rolloverDays", val);

        if (!hasError) {
            this.props.setAccountError('');
        }
        else {
            this.props.thresholdError(hasError)
        }
    }

    convertNumberValue = (text) => {
        const num = Number(text);
        return isNaN(num) ? 0 : num;
    }

    checkValue = (val, valueType, fromSubmit) => {

        switch (valueType) {

            case "rolloverDays":

                if (val < 0) {
                    return T.translate('threshold.minimumRolloverDays');
                }

                if (val > 180) {
                    return T.translate('threshold.maximumRolloverDays');
                }

                break;

            case "lowTreshold":

                if (val < 0) {
                    return T.translate('threshold.minimumLowThreshold');
                }

                if (val > 999) {
                    return T.translate('threshold.maximumLowThreshold');
                }

                if (val > this.props.thresholdDetails.thresholdHigh && fromSubmit) {
                    return T.translate('threshold.minimumGreaterMaximumThreshold');
                }

                break;

            case "thresholdHigh":

                if (val < 0) {
                    return T.translate('threshold.minimumHighThreshold');
                }

                if (val > 999) {
                    return T.translate('threshold.maximumHighThreshold');
                }

                if (val < this.props.thresholdDetails.thresholdLow && fromSubmit) {
                    return T.translate('threshold.maximumLessMinimumThreshold');
                }

                break;
        }

        return "";

    }

    onSubmit = (e) => {

        let state = this.state;

        let thresholdData = {
            serialNumber: state.selectedSerialNumber,
            thresholdClusterID: this.props.thresholdDetails.thresholdClusterID,
            value: {
                rolloverDays: this.props.thresholdDetails.thresholdRolloverDays,
                lowTreshold: this.props.thresholdDetails.thresholdLow,
                highTreshold: this.props.thresholdDetails.thresholdHigh,
                notifyUser: 1
            }
        }

        //Check value before submit

        let hasErrorLow = this.checkValue(this.props.thresholdDetails.thresholdLow, "lowTreshold", true);
        let hasErrorHigh = this.checkValue(this.props.thresholdDetails.thresholdHigh, "thresholdHigh", true);
        let hasErrorDays = this.checkValue(this.props.thresholdDetails.thresholdRolloverDays, "rolloverDays", true);

        if (hasErrorLow || hasErrorHigh || hasErrorDays) {

            if (hasErrorLow) {
                this.props.thresholdError(hasErrorLow)
            }

            if (hasErrorHigh) {
                this.props.thresholdError(hasErrorHigh)
            }

            if (hasErrorDays) {
                this.props.thresholdError(hasErrorDays)
            }

            return;
        }
        else {
            this.props.setAccountError('');
        }

        this.props.saveThreshold(thresholdData);
    }

    onCancelClick = (e) => {

        this.setState({ selectedSerialNumber: 0 });
        this.props.setAccountError('');
    }

    onChangeThreshold = (e) => {
        let val = e.target.value;
    }

    isWithinRange = (val, minVal, maxVal) => {

        if (val < minVal || val > maxVal) {
            return false;
        }

        return true;

    }

    isNumeric = (n) => {
        return !isNaN(parseFloat(n)) && isFinite(n);
    }

    setHighlightStyle = (columnId) => {
        var styleClass = '';

        if (columnId === 'thresholdChannelName') {
            styleClass = 'plan-current kanit font-weight-normal';
        }

        return styleClass;
    }

    render() {
        return (
            <div className="p-main">
                <LoadingOverlay loading={this.props.accountLoading} />

                <div className="page-header">
                    <div className="page-title">{T.translate('threshold.pageTitle')}</div>
                    <p className="lead text-dark font-weight-normal">
                        {T.translate('threshold.manageThresholdSubTitle')}
                    </p>
                </div>
                <div className="diverter" />
                <ul className="nav nav-tabs">
                    <li className="nav-item"><a className={"nav-link " + (this.state.activeTab === '1' ? "active" : "")} onClick={this.toggleActiveTab} data-tab="1" href="javascript:;">{T.translate('threshold.thresholdOffsets')}</a></li>
                </ul>
                <div className="tab-content mb-4">

                    {this.state.activeTab === '1' && <div className={"tab-pane " + (this.state.activeTab === '1' ? "active" : "")}>

                        <div className="card">
                            <div className="card-body">
                                <div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="meterList">{T.translate('threshold.meter')}</label>
                                                <select className="form-control" name="meterList" id="meterList" value={this.state.selectedSerialNumber} onChange={this.onChangeMeter} >
                                                    <option value="" selected>{T.translate("threshold.selectMeter")}</option>
                                                    {
                                                        this.props.accountMeterList.map(function (meter) {
                                                            return <option key={meter.meterSerialNumber}
                                                                value={meter.meterSerialNumber}>{meter.meterSerialNumber} : {meter.meterTypeName} ({meter.unitOfMeasureCode})</option>;
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    {this.state.selectedSerialNumber != 0 && <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label id="lblminimumThreshold" htmlFor="minimumThreshold">{T.translate('threshold.minimumThreshold')}</label>
                                                <input type="number" id="minimumThreshold" name="minimumThreshold" value={this.props.thresholdDetails.thresholdLow} onChange={this.onChangeLow} className="form-control" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="maximumThreshold">{T.translate('threshold.maximumThreshold')}</label>
                                                <input type="number" id="maximumThreshold" name="maximumThreshold" value={this.props.thresholdDetails.thresholdHigh} onChange={this.onChangeHigh} className="form-control" />
                                            </div>
                                        </div>
                                    </div>}
                                </div>
                                {this.state.selectedSerialNumber != 0 && <div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="rolloverDays">{T.translate('threshold.rolloverDays')}</label>
                                                <input type="number" id="rolloverDays" name="rolloverDays" value={this.props.thresholdDetails.thresholdRolloverDays} onChange={this.onChangeRolloverDays} className="form-control" />
                                            </div>
                                        </div>
                                    </div>
                                </div>}

                                <MessageError>{this.props.ErrorMessage}</MessageError>

                                {this.state.selectedSerialNumber != 0 && <div className="diverter mt-0 mb-3" />}

                                {this.state.selectedSerialNumber != 0 && <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <a href="javascript:;" onClick={this.onCancelClick} className="btn btn-outline-dark mr-3">{T.translate('common.cancel')}</a>
                                            <LoadingButton
                                                className="btn btn-primary"
                                                onClick={this.onSubmit}
                                                loading={this.props.accountLoading ? 'true' : 'false'}>
                                                {T.translate('threshold.save')}
                                            </LoadingButton>
                                        </div>
                                    </div>
                                </div>}

                            </div>
                        </div>

                    </div>}

                    {this.state.activeTab === '2' && <div className={"tab-pane " + (this.state.activeTab === '2' ? "active" : "")}>

                        {/*<DataGrid dataKey="accountTransactionID" usePaging downloadData csvFileName={'Thresholds ' + Date() + '.csv'} expandibleContent={false} pageSize="15" onHighlightStyle={this.setHighlightStyle}>*/}
                        {/*    {{*/}
                        {/*        rows: this.props.accountCommunications,*/}
                        {/*        columns: [*/}
                        {/*            { columnId: 'communicationDate', displayText: T.translate('threshold.date'), dataType: 'date', format: 'MM/dd/yyyy', bold: true, italic: true },*/}
                        {/*            { columnId: 'communicationTypeText', displayText: T.translate('threshold.category') },*/}
                        {/*            { columnId: 'recipient', displayText: T.translate('threshold.recipient') },*/}
                        {/*            { columnId: 'thresholdChannelName', displayText: T.translate('threshold.channel'), hightlightCell: true },*/}
                        {/*            { columnId: 'communicationText', displayText: T.translate('threshold.content'), style: { width: '50%' } }*/}
                        {/*        ]*/}
                        {/*    }}*/}
                        {/*</DataGrid>*/}

                    </div>}

                </div>

                <MessageError>{this.props.accountErrorMessage}</MessageError>

                <ConfirmModal
                    title={T.translate('threshold.updateThreshold')}
                    open={this.props.showUpdateAccountSuccessModal}
                    onToggle={this.props.toggleUpdateAccountSuccessModal}
                    loading={this.props.accountLoading}>
                    <div>
                        <p>{T.translate('threshold.thresholdsUpdated')}</p>
                        <p className="font-weight-bold">{T.translate('threshold.thresholdSuccessfullyUpdated')}</p>
                    </div>
                </ConfirmModal>

            </div>
        );
    }
}

export default connect(
    state => ({ ...state.account, ...state.common }),
    dispatch => bindActionCreators({ ...AccountStore.actionCreators, ...CommonStore.actionCreators }, dispatch)
)(Threshold);