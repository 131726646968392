import de from 'date-fns/esm/locale/de/index.js';
import HighchartsPatternFill from 'highcharts-pattern-fill';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';
import drilldown from 'highcharts/modules/drilldown';
import T from 'i18n-react';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as CommonStore from '../../../store/Common';
const plugWater = require(`../../../images/${process.env.REACT_APP_CLIENT}/plug-icon-water.png`);

drilldown(Highcharts);
HighchartsPatternFill(Highcharts);

class WaterUsageTab extends Component {

    constructor(props) {
        super(props);

        this.onIntervalWaterClick = this.onIntervalWaterClick.bind(this);

        this.state = {
            chartWaterTypeID: '1',
            intervalWater: false,
            intervalDateWater: '',
            calculationLegendText: '',
            chartData: {},
            highchartsOptionMonthly: {},
            highchartsOptionDaily: {},
            highchartsOptionInterval: {},
            highchartsOptionMonthlyWithWaterSpend: {
                credits: {
                    enabled: false
                },
                title: {
                    text: ''
                },
                chart: {
                    zoomType: 'xy',
                    style: {
                        fontFamily: "sans-serif;"
                    }
                },
                scrollbar: {
                    enabled: true
                },
                xAxis: [{
                    categories: [],
                    crosshair: true,
                    max: null
                }],
                yAxis: [{ // Primary yAxis
                    title: {
                        text: T.translate('dashboard.waterSpend'),
                        style: {
                            color: Highcharts.getOptions().colors[1]
                        }
                    },
                    labels: {
                        format: '${value}',
                        style: {
                            color: Highcharts.getOptions().colors[1]
                        }
                    }
                }, { // Secondary yAxis
                    title: {
                        text: T.translate('dashboard.galUsed'),
                        style: {
                            color: Highcharts.getOptions().colors[0]
                        }
                    },
                    labels: {
                        format: '{value} ' + T.translate('dashboard.galUnit'),
                        style: {
                            color: Highcharts.getOptions().colors[0]
                        }
                    },
                    opposite: true
                }],
                tooltip: {
                    shared: true
                },
                legend: {
                    layout: 'vertical',
                    align: 'left',
                    x: 70,
                    verticalAlign: 'top',
                    y: 10,
                    floating: true,
                    backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || 'rgba(255,255,255,0.25)'
                },
                series: [
                    {
                        name: T.translate('dashboard.galUsed'),
                        type: 'column',
                        yAxis: 1,
                        data: [],
                        tooltip: {
                            valueSuffix: ' ' + T.translate('dashboard.galUnit')
                        }
                    },
                    {
                        name: T.translate('dashboard.waterSpend'),
                        type: 'spline',
                        data: [],
                        tooltip: {
                            valuePrefix: '$'
                        }
                    }
                ]
            },
            highchartsOptionDailyWithWaterSpend: {
                defs: {
                    patterns: [
                        {
                            id: 'billed-estimated-pattern',
                            path: {
                                d: 'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11',
                                stroke: Highcharts.getOptions().colors[0],
                                strokeWidth: 3
                            }
                        },
                        {
                            id: 'unbilled-estimated-pattern',
                            path: {
                                d: 'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11',
                                stroke: '#ecb67c',
                                strokeWidth: 3
                            }
                        }
                    ]
                },
                credits: {
                    enabled: false
                },
                title: {
                    text: ''
                },
                chart: {
                    zoomType: 'xy',
                    style: {
                        fontFamily: "sans-serif;"
                    }
                },
                scrollbar: {
                    enabled: true
                },
                xAxis: [{
                    categories: [],
                    crosshair: true,
                    max: null
                }],
                yAxis: [{ // Primary yAxis
                    title: {
                        text: T.translate('dashboard.waterSpend'),
                        style: {
                            color: Highcharts.getOptions().colors[1]
                        }
                    },
                    labels: {
                        format: '${value}',
                        style: {
                            color: Highcharts.getOptions().colors[1]
                        }
                    }
                }, { // Secondary yAxis
                    title: {
                        text: T.translate('dashboard.galUsed'),
                        style: {
                            color: Highcharts.getOptions().colors[0]
                        }
                    },
                    labels: {
                        format: '{value} ' + T.translate('dashboard.galUnit'),
                        style: {
                            color: Highcharts.getOptions().colors[0]
                        }
                    },
                    opposite: true
                }],
                tooltip: {
                    shared: true
                },
                legend: {
                    layout: 'vertical',
                    align: 'left',
                    x: 70,
                    verticalAlign: 'top',
                    y: 10,
                    floating: true,
                    backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || 'rgba(255,255,255,0.25)',
                    useHTML: true,
                    labelFormatter: function () {

                        if (this.name === T.translate('dashboard.galUsed')) {
                            return '<div>' + T.translate('dashboard.billedUsage') + ' <span style="width:12px; height:12px; background-color:#ecb67c; display:inline-block; border-radius:500px; margin-left:20px; margin-right:4px;"></span>' + T.translate('dashboard.unbilledUsage') + '</div>';
                        } else {
                            return T.translate('dashboard.waterSpend');
                        }

                    }
                },
                plotOptions: {
                    column: {
                        cursor: 'pointer',
                        point: {
                            events: {
                                click: this.onIntervalWaterClick
                            }
                        }
                    }
                },
                series: [
                    {
                        name: T.translate('dashboard.galUsed'),
                        type: 'column',
                        yAxis: 1,
                        data: [],
                        tooltip: {
                            valueSuffix: ' ' + T.translate('dashboard.galUnit')
                        }
                    },
                    {
                        name: T.translate('dashboard.waterSpend'),
                        type: 'spline',
                        data: [],
                        tooltip: {
                            valuePrefix: '$'
                        }
                    }
                ],
                drilldown: {
                    series: []
                }
            },
            highchartsOptionIntervalWithWaterSpend: {
                defs: {
                    patterns: [
                        {
                            id: 'billed-estimated-pattern',
                            path: {
                                d: 'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11',
                                stroke: Highcharts.getOptions().colors[0],
                                strokeWidth: 3
                            }
                        }
                    ]
                },
                credits: {
                    enabled: false
                },
                title: {
                    text: ''
                },
                chart: {
                    zoomType: 'xy',
                    style: {
                        fontFamily: "sans-serif;"
                    }
                },
                scrollbar: {
                    enabled: true
                },
                xAxis: [{
                    categories: [],
                    crosshair: true,
                    max: null
                }],
                yAxis: [{ // Primary yAxis
                    title: {
                        text: T.translate('dashboard.waterSpend'),
                        style: {
                            color: Highcharts.getOptions().colors[1]
                        }
                    },
                    labels: {
                        format: '${value}',
                        style: {
                            color: Highcharts.getOptions().colors[1]
                        }
                    }
                }, { // Secondary yAxis
                    title: {
                        text: T.translate('dashboard.galUsed'),
                        style: {
                            color: Highcharts.getOptions().colors[0]
                        }
                    },
                    labels: {
                        format: '{value} ' + T.translate('dashboard.galUnit'),
                        style: {
                            color: Highcharts.getOptions().colors[0]
                        }
                    },
                    opposite: true
                }],
                tooltip: {
                    shared: true
                },
                legend: {
                    layout: 'vertical',
                    align: 'left',
                    x: 70,
                    verticalAlign: 'top',
                    y: 10,
                    floating: true,
                    backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || 'rgba(255,255,255,0.25)'
                },
                series: [
                    {
                        name: T.translate('dashboard.galUsed'),
                        type: 'column',
                        yAxis: 1,
                        data: [],
                        tooltip: {
                            valueSuffix: ' ' + T.translate('dashboard.galUnit')
                        }
                    },
                    {
                        name: T.translate('dashboard.waterSpend'),
                        type: 'spline',
                        data: [],
                        tooltip: {
                            valuePrefix: '$'
                        }
                    }
                ]
            },
            highchartsOptionMonthlyWithoutWaterSpend: {
                credits: {
                    enabled: false
                },
                title: {
                    text: ''
                },
                chart: {
                    zoomType: 'xy',
                    style: {
                        fontFamily: "sans-serif;"
                    }
                },
                scrollbar: {
                    enabled: true
                },
                xAxis: [{
                    categories: [],
                    crosshair: true,
                    max: null
                }],
                yAxis: { // Secondary yAxis
                    title: {
                        text: T.translate('dashboard.galUsed'),
                        style: {
                            color: Highcharts.getOptions().colors[0]
                        }
                    },
                    labels: {
                        format: '{value} ' + T.translate('dashboard.galUnit'),
                        style: {
                            color: Highcharts.getOptions().colors[0]
                        }
                    },
                    opposite: false
                },
                tooltip: {
                    shared: true
                },
                legend: {
                    layout: 'vertical',
                    align: 'left',
                    x: 70,
                    verticalAlign: 'top',
                    y: 10,
                    floating: true,
                    backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || 'rgba(255,255,255,0.25)'
                },
                series: [
                    {
                        name: T.translate('dashboard.galUsed'),
                        type: 'column',
                        data: [],
                        tooltip: {
                            valueSuffix: ' ' + T.translate('dashboard.galUnit')
                        }
                    }
                ]
            },
            highchartsOptionDailyWithoutWaterSpend: {
                defs: {
                    patterns: [
                        {
                            id: 'billed-estimated-pattern',
                            path: {
                                d: 'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11',
                                stroke: Highcharts.getOptions().colors[0],
                                strokeWidth: 3
                            }
                        },
                        {
                            id: 'unbilled-estimated-pattern',
                            path: {
                                d: 'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11',
                                stroke: '#ecb67c',
                                strokeWidth: 3
                            }
                        }
                    ]
                },
                credits: {
                    enabled: false
                },
                title: {
                    text: ''
                },
                chart: {
                    zoomType: 'xy',
                    style: {
                        fontFamily: "sans-serif;"
                    }
                },
                scrollbar: {
                    enabled: true
                },
                xAxis: [{
                    categories: [],
                    crosshair: true,
                    max: null
                }],
                yAxis: { // Secondary yAxis
                    title: {
                        text: T.translate('dashboard.galUsed'),
                        style: {
                            color: Highcharts.getOptions().colors[0]
                        }
                    },
                    labels: {
                        format: '{value} ' + T.translate('dashboard.galUnit'),
                        style: {
                            color: Highcharts.getOptions().colors[0]
                        }
                    },
                    opposite: false
                },
                tooltip: {
                    shared: true
                },
                legend: {
                    layout: 'vertical',
                    align: 'left',
                    x: 70,
                    verticalAlign: 'top',
                    y: 10,
                    floating: true,
                    backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || 'rgba(255,255,255,0.25)',
                    useHTML: true,
                    labelFormatter: function () {

                        if (this.name === T.translate('dashboard.galUsed')) {
                            return '<div>' + T.translate('dashboard.billedUsage') + ' <span style="width:12px; height:12px; background-color:#ecb67c; display:inline-block; border-radius:500px; margin-left:20px; margin-right:4px;"></span>' + T.translate('dashboard.unbilledUsage') + '</div>';
                        }
                    }
                },
                plotOptions: {
                    column: {
                        cursor: 'pointer',
                        point: {
                            events: {
                                click: this.onIntervalWaterClick
                            }
                        }
                    }
                },
                series: [
                    {
                        name: T.translate('dashboard.galUsed'),
                        type: 'column',
                        data: [],
                        tooltip: {
                            valueSuffix: ' ' + T.translate('dashboard.galUnit')
                        }
                    }
                ],
                drilldown: {
                    series: []
                }
            },
            highchartsOptionIntervalWithoutWaterSpend: {
                defs: {
                    patterns: [
                        {
                            id: 'billed-estimated-pattern',
                            path: {
                                d: 'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11',
                                stroke: Highcharts.getOptions().colors[0],
                                strokeWidth: 3
                            }
                        }
                    ]
                },
                credits: {
                    enabled: false
                },
                title: {
                    text: ''
                },
                chart: {
                    zoomType: 'xy',
                    style: {
                        fontFamily: "sans-serif;"
                    }
                },
                scrollbar: {
                    enabled: true
                },
                xAxis: [{
                    categories: [],
                    crosshair: true,
                    max: null
                }],
                yAxis: [{ // Secondary yAxis
                    title: {
                        text: T.translate('dashboard.galUsed'),
                        style: {
                            color: Highcharts.getOptions().colors[0]
                        }
                    },
                    labels: {
                        format: '{value} ' + T.translate('dashboard.galUnit'),
                        style: {
                            color: Highcharts.getOptions().colors[0]
                        }
                    },
                    opposite: false
                }],
                tooltip: {
                    shared: true
                },
                legend: {
                    layout: 'vertical',
                    align: 'left',
                    x: 70,
                    verticalAlign: 'top',
                    y: 10,
                    floating: true,
                    backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || 'rgba(255,255,255,0.25)'
                },
                series: [
                    {
                        name: T.translate('dashboard.galUsed'),
                        type: 'column',
                        data: [],
                        tooltip: {
                            valueSuffix: ' ' + T.translate('dashboard.galUnit')
                        }
                    }
                ]
            }
        };
    }


    componentWillMount() {
        if (this.props.active) {
            this.setChartData(this.props, true);
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setChartData(nextProps, false);
    }

    setChartData = (props, requestDataSource) => {
        if (this.props.active) {
            if (requestDataSource && props.asp.accountServicePointID) {
                props.onDataSourceRequest({ chartTypeID: this.state.chartWaterTypeID, accountServicePointID: props.asp.accountServicePointID });
                return;
            }

            if (props.chartData) {

                var chartData = props.chartData;

                if (chartData.activityMonthlyWaterCategories && chartData.activityMonthlyWaterSeries && chartData.activityMonthlyWaterSeries2) {

                    let highchartsOptionMonthly;
                    chartData.cycleTerm = this.props.account.locationServices[0].cycleTerm;

                    if (!chartData.activityHiddenWaterCharges)
                    {
                        highchartsOptionMonthly = { ...this.state.highchartsOptionMonthlyWithWaterSpend };
                        highchartsOptionMonthly.series[1].data = chartData.activityMonthlyWaterSeries2;

                    }
                    else
                    {
                        highchartsOptionMonthly = { ...this.state.highchartsOptionMonthlyWithoutWaterSpend };
                        highchartsOptionMonthly.series[0].data = chartData.activityMonthlyWaterSeries;
                    }

                    highchartsOptionMonthly.xAxis[0].categories = chartData.activityMonthlyWaterCategories;
                    highchartsOptionMonthly.scrollbar = {};
                    highchartsOptionMonthly.xAxis[0].max = chartData.activityMonthlyWaterCategories.length > 15 ? 13 : null;

                    this.setState({ highchartsOptionMonthly });

                    if (this.refs.highchartsOptionMonthly) {
                        this.refs.highchartsOptionMonthly.chart.xAxis[0].setExtremes(undefined, undefined, true, true);
                        this.refs.highchartsOptionMonthly.chart.redraw();
                        this.refs.highchartsOptionMonthly.chart.update({
                            scrollbar: {
                                enabled: chartData.activityMonthlyWaterCategories.length > 15
                            }
                        })
                    }
                }

                if (chartData.activityDailyWaterCategories && chartData.activityDailyWaterSeries && chartData.activityDailyWaterSeries2) {

                    let highchartsOptionDaily;

                    if (!chartData.activityHiddenWaterCharges)
                    {
                        highchartsOptionDaily = { ...this.state.highchartsOptionDailyWithWaterSpend };
                        highchartsOptionDaily.series[1].data = chartData.activityDailyWaterSeries2;
                    }
                    else
                    {
                        highchartsOptionDaily = { ...this.state.highchartsOptionDailyWithoutWaterSpend };
                    }

                    highchartsOptionDaily.xAxis[0].categories = chartData.activityDailyWaterCategories;
                    highchartsOptionDaily.series[0].data = chartData.activityDailyWaterSeries;

                    highchartsOptionDaily.scrollbar = {};
                    highchartsOptionDaily.xAxis[0].max = chartData.activityDailyWaterCategories.length > 15 ? 13 : null;

                    this.setState({ highchartsOptionDaily });

                    if (this.refs.highchartsOptionDaily) {
                        this.refs.highchartsOptionDaily.chart.xAxis[0].setExtremes(undefined, undefined, true, true);
                        this.refs.highchartsOptionDaily.chart.redraw();
                        this.refs.highchartsOptionDaily.chart.update({
                            scrollbar: {
                                enabled: chartData.activityDailyWaterCategories.length > 15
                            }
                        })
                    }
                }

                if (chartData.activityIntervalWaterCategories && chartData.activityIntervalWaterSeries && chartData.activityIntervalWaterSeries2) {

                    let highchartsOptionInterval;

                    if (!chartData.activityHiddenWaterCharges)
                    {
                        highchartsOptionInterval = { ...this.state.highchartsOptionIntervalWithWaterSpend };
                        highchartsOptionInterval.series[1].data = chartData.activityIntervalWaterSeries2;
                    }
                    else {
                        highchartsOptionInterval = { ...this.state.highchartsOptionIntervalWithoutWaterSpend };
                    }

                    highchartsOptionInterval.xAxis[0].categories = chartData.activityIntervalWaterCategories;
                    highchartsOptionInterval.series[0].data = chartData.activityIntervalWaterSeries;
                    highchartsOptionInterval.scrollbar = {};
                    highchartsOptionInterval.xAxis[0].max = chartData.activityIntervalWaterCategories.length > 15 ? 13 : null;

                    this.setState({ highchartsOptionInterval });

                    if (this.refs.highchartsOptionInterval) {
                        this.refs.highchartsOptionInterval.chart.xAxis[0].setExtremes(undefined, undefined, true, true);
                        this.refs.highchartsOptionInterval.chart.redraw();
                        this.refs.highchartsOptionInterval.chart.update({
                            scrollbar: {
                                enabled: chartData.activityIntervalWaterCategories.length > 15
                            }
                        })
                    }
                }

                this.setState({ chartData });

                this.getCalculationLegend(chartData);
            }

        }

    }

    onChartWaterChange = (e) => {
        const state = this.state;
        state[e.target.name] = e.target.value;
        this.setState(state);

        this.props.onDataSourceRequest({ chartTypeID: this.state.chartWaterTypeID, accountServicePointID: this.props.asp.accountServicePointID });

        if (this.state.chartWaterTypeID === '1') {
            this.backToDailyWater();
        }

        this.getCalculationLegend(this.state.chartData);
    }

    onIntervalWaterClick = (e) => {
        if (e.point && e.point.category) {
            this.toggleIntervalWater(e.point.category, this.props);
        }
    }

    backToDailyWater = (e) => {
        this.setState({ intervalWater: false, intervalDateWater: '' });
    }

    toggleIntervalWater = (category, props) => {
        this.setState({ intervalWater: true, intervalDateWater: category });
        props.onDataSourceRequest({ chartTypeID: '3', accountServicePointID: props.asp.accountServicePointID, date: category });
    }
        
    getCalculationLegend = (chartData) => {

        let calculationLegendText = '';
        let periodID = 0;
        let resourceKey = '';
        let activityStatistics = {};

        periodID = this.state.chartWaterTypeID;
        
        if (periodID === '1') {
            activityStatistics = chartData.activityWaterMonthlyStatistics;
        } else if (periodID === '2') {
            activityStatistics = chartData.activityWaterDailyStatistics;
        }

        //Check if the charts are configured for cycle terms.
        //Set PeriodID to 3 if comfirmed to be a cycle
        periodID = chartData.cycleTerm > 1 ? '3' : periodID;
        
        if (activityStatistics) {
            activityStatistics.unit = T.translate('dashboard.galUnit');
            if (activityStatistics.isUsagePresent) {

                if (periodID === '1') {

                    activityStatistics.perMonth.formatedDate = CommonStore.formatDate(activityStatistics.perMonth.date, 'MMMM YYYY');
                    resourceKey = '';

                    if (activityStatistics.perMonth.isPreviousMonthPesent || activityStatistics.perMonth.isPreviousYearMonthPesent) {

                        activityStatistics.perMonth.formatedPreviousMonthDate = CommonStore.formatDate(activityStatistics.perMonth.previousMonthDate, 'MMMM YYYY');

                        var resourceKeyArr = [];

                        if (activityStatistics.perMonth.previousMonthAmountDiff > 0) {
                            resourceKeyArr.push('dashboard.yourAvgDailyCostMonthV2MoreThan');
                        } else if (activityStatistics.perMonth.previousMonthAmountDiff < 0) {
                            resourceKeyArr.push('dashboard.yourAvgDailyCostMonthV2LessThan');
                        } else {
                            resourceKeyArr.push('dashboard.yourAvgDailyCostMonthV2Equal');
                        }

                        if (activityStatistics.perMonth.isPreviousYearMonthPesent) {
                            if (activityStatistics.perMonth.previousYearMonthAmountDiff > 0) {
                                resourceKeyArr.push('dashboard.yourAvgDailyCostMonthV3MoreThan');
                            } else if (activityStatistics.perMonth.previousYearMonthAmountDiff < 0) {
                                resourceKeyArr.push('dashboard.yourAvgDailyCostMonthV3LessThan');
                            } else {
                                resourceKeyArr.push('dashboard.yourAvgDailyCostMonthV3Equal');
                            }
                        }

                        let translations = resourceKeyArr.map(x => T.translate(x, activityStatistics));

                        calculationLegendText = translations.join(' ');

                    } else {
                        resourceKey = 'dashboard.yourAvgDailyCostMonthV1';
                        calculationLegendText = T.translate(resourceKey, activityStatistics);
                    }
                } else if (periodID === '2') {

                    activityStatistics.perDay.formatedFromDate = CommonStore.formatDate(activityStatistics.perDay.startDate, 'MM/DD/YYYY');
                    activityStatistics.perDay.formatedToDate = CommonStore.formatDate(activityStatistics.perDay.endDate, 'MM/DD/YYYY');

                    if (activityStatistics.perDay.isPreviousWeekPesent) {

                        if (activityStatistics.perDay.previousWeekAmountDiff > 0) {
                            resourceKey = 'dashboard.yourAvgDailyCostWeekV2MoreThan';
                        } else if (activityStatistics.perDay.previousWeekAmountDiff < 0) {
                            resourceKey = 'dashboard.yourAvgDailyCostWeekV2LessThan';
                        } else {
                            resourceKey = 'dashboard.yourAvgDailyCostWeekV2Equal';
                        }

                    } else {
                        resourceKey = 'dashboard.yourAvgDailyCostWeekV1';
                    }

                    calculationLegendText = T.translate(resourceKey, activityStatistics);
                }
                else if (periodID === '3') {
                    resourceKey = 'dashboard.yourUsageCycleTerm';

                    var cycleTerm;

                    if (chartData.cycleTerm === 1) {
                        cycleTerm = T.translate('dashboard.cycleTerm1Month')
                    }
                    else if (chartData.cycleTerm === 2) {
                        cycleTerm = T.translate('dashboard.cycleTerm2Month')
                    }
                    else if (chartData.cycleTerm === 3) {
                        cycleTerm = T.translate('dashboard.cycleTerm3Month')
                    }
                    else if (chartData.cycleTerm === 4) {
                        cycleTerm = T.translate('dashboard.cycleTerm4Month')
                    }
                    else if (chartData.cycleTerm === 6) {
                        cycleTerm = T.translate('dashboard.cycleTerm6Month')
                    }
                    else if (chartData.cycleTerm === 12) {
                        cycleTerm = T.translate('dashboard.cycleTerm12Month')
                    }
                    else {
                        cycleTerm = T.translate('dashboard.cycleTerm1Month')
                    }
                    
                    calculationLegendText = T.translate(resourceKey, { cycleTerm: cycleTerm });
                }
            }

        }

        this.setState({ calculationLegendText });
    }

    render() {
        return (
            <div id={this.props.id} className={"tab-pane " + (this.props.active ? "active" : "")}>
                <div className="row">
                    <div className="col-md-9">

                        {this.state.chartWaterTypeID === '1' &&
                            <HighchartsReact
                            ref="highchartsOptionMonthly"
                                highcharts={Highcharts}
                            options={this.state.highchartsOptionMonthly}
                            />
                        }

                        {this.state.chartWaterTypeID === '2' && !this.state.intervalWater &&
                            <HighchartsReact
                                ref="highchartsOptionDaily"
                                highcharts={Highcharts}
                                options={this.state.highchartsOptionDaily}
                            />
                        }

                        {this.state.chartWaterTypeID === '2' && this.state.intervalWater &&
                            <HighchartsReact
                                ref="highchartsOptionInterval"
                                highcharts={Highcharts}
                                options={this.state.highchartsOptionInterval}
                            />
                        }

                    </div>
                    <div className="col-md-3 align-self-center">
                        <div className="tab-image">
                            {!this.props.asp.serviceTypeID && <img src={plugWater} alt="plug icon" />}
                            {this.props.asp.serviceTypeID &&
                                <React.Fragment>
                                    <img src={require(`../../../images/${process.env.REACT_APP_CLIENT}/plug-icon-${this.props.asp.serviceTypeID}.png`)} alt="plug icon" />
                                    <div className="image-text">
                                        {this.props.asp.linkedServiceTypeID === -1 && T.translate('dashboard.serviceType' + this.props.asp.serviceTypeID)}
                                        {this.props.asp.linkedServiceTypeID > 0 && T.translate('dashboard.serviceType' + this.props.asp.serviceTypeID) + ' & ' + T.translate('dashboard.serviceType' + this.props.asp.linkedServiceTypeID)}
                                    </div>
                                </React.Fragment>
                            }
                        </div>

                        <h6 className="mt-3 mb-3 card-title">{T.translate('dashboard.myUsageInsights')}</h6>

                        {!this.state.intervalWater &&
                            <p className="mb-4">{this.state.calculationLegendText}</p>
                        }

                        {this.state.intervalWater &&
                            <div>
                                <p className="mb-4">{T.translate('dashboard.showingIntervalConsumptionFor')} {this.state.intervalDateWater}</p>
                                <p className="mb-4"><button className="btn btn-primary" type="button" onClick={this.backToDailyWater}>{T.translate('dashboard.backToDailyView')}</button></p>
                            </div>
                        }
                        
                        {this.props.showDailyAndDailyCharts && !this.state.intervalWater && this.props.systemProperties.billDocumentGenerationChartSetting && (
                            <select
                                className="form-control"
                                id="chartWaterTypeID"
                                name="chartWaterTypeID"
                                value={this.state.chartWaterTypeID}
                                onChange={this.onChartWaterChange}>
                                {this.props.account.locationServices.map((service, index) => (
                                    <option value={index + 1}>
                                        {
                                            service.cycleTerm === 1 ? T.translate('dashboard.cycleTerm1Month') :
                                            service.cycleTerm === 2 ? T.translate('dashboard.cycleTerm2Month') :
                                            service.cycleTerm === 3 ? T.translate('dashboard.cycleTerm3Month') :
                                            service.cycleTerm === 4 ? T.translate('dashboard.cycleTerm4Month') :
                                            service.cycleTerm === 6 ? T.translate('dashboard.cycleTerm6Month') :
                                            service.cycleTerm === 12 ? T.translate('dashboard.cycleTerm12Month') :
                                            T.translate('dashboard.cycleTerm1Month') }
                                    </option>
                                ))}
                                <option value={this.props.account.locationServices &&
                                               this.props.account.locationServices.length > 0 ? this.props.account.locationServices.length + 1 : 1}>
                                    {T.translate('dashboard.daily')}
                                </option>
                            </select>
                        )}

                        {this.props.showDailyAndDailyCharts && !this.state.intervalWater && !this.props.systemProperties.billDocumentGenerationChartSetting && (
                            <select
                                className="form-control"
                                id="chartWaterTypeID"
                                name="chartWaterTypeID"
                                value={this.state.chartWaterTypeID}
                                onChange={this.onChartWaterChange}>
                                <option value="1">{T.translate('dashboard.monthly')}</option>
                                <option value="2">{T.translate('dashboard.daily')}</option>
                            </select>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    state => ({ ...state.common }),
    dispatch => bindActionCreators({ ...CommonStore.actionCreators }, dispatch)
)(WaterUsageTab);